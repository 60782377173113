.eta_form {
  margin-top: 30px;
}

div {
  font-family: sans-serif;
}

.textInput {
  margin-top: 1em;
  margin-bottom: 1em;
}

fieldset {
  border: 0ch;
}

.move_type {
  margin-top: 1em;
}

button {
  padding: 0.25rem 0.5rem;
  color: #fff;
  background-color: #e81c2d;
  border-color: #e81c2d;
  font-weight: 700 !important;
}

.mobile {
  visibility: hidden;
}

.desktop {
  visibility: visible;
}

.long-row {
  width: 70%;
}

.idLabel {
  margin-left: 10px;
}

.rc-time-picker-input {
  color: black;
  font-weight: 500;
}

.timepicker {
  margin-top: 6px;
}
.title
{
  color: #b01313;
  font-size: 200%;
  font-family: 'Oswald', sans-serif;
}

/* Style the container */
.container {
  border-radius: 75px 75px;
  background-color: #F0F7FB;
  padding: 30px;
  border: 2px light red;
  border-radius: 5px;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  
  border-bottom-right-radius: 25px;
  
  border-bottom-right-radius: 25px;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.font-weight-bold {
  font-weight: 700 !important;
}

/* Floating column for labels: 25% width */
.col-15 {
  float: left;
  width: 15%;
  margin-top: 6px;
}

/* Floating column for labels: 25% width */
.col-25 {
  float: left;
  width: 25%;
  margin-top: 6px;
}

/* Floating column for inputs: 75% width */
.col-75 {
  float: left;
  width: 75%;
  margin-top: 6px;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

.react-time-picker {
  margin-top: 1em;
}

.error {
  color: red;
  font-weight: bold;
}

.success {
  border-color: green;
  background-color: #BDECD6;
  color: green;
  font-weight: 600;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  text-align: center;
}

.date-option {
  list-style-type: none;
  margin: 25px 0 0 0;
  padding: 0;
}

.date-option li {
  float: left;
  margin: 0 5px 0 0;
  width: 100px;
  height: 40px;
  position: relative;
}

.date-option label,
.date-option input {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.date-option input[type="radio"] {
  opacity: 0.011;
  z-index: 100;
}

.date-option input[type="radio"]:checked+label {
  background: #a5dc86;
}

.date-option label {
  padding: 5px;
  border: 1px solid #CCC;
  cursor: pointer;
  z-index: 90;
  color: rgba(0, 0, 0, 1);
  font-size: 14px;
  line-height: 1;
  vertical-align: middle;
  text-align: center;
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.date-option label:hover {
  background: #DDD;
}

.captcha {
  width: 100%;
  margin-top: 20px !important;

}

.captcha div {
  margin-top: 10px;
}


@media (max-width: 479px) {
  .head-info {
    width: 30%;
  }

  .col-15 {
    width: 90%;
    margin-top: 1px;
    margin-bottom: 3px;
  }

  .col-25 {
    margin-top: 1px;
    width: 90%;
  }

  .col-75 {
    width: 85%;
    margin-top: 0;
  }

  .row {
    margin-bottom: 5px;
    margin-top: 10px;
  }

  .rdp {
    font-size: small;
  }


  .move_type {
    margin-top: 1px;
  }

  select {
    margin-top: 1px;
  }

  p {
    margin: 0px;
  }

  .success {
    width: 100%;
  }

  .mobile {
    visibility: visible;
    width: 100%;
  }

  .mobile-div {
    width: 100%;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .desktop {
    visibility: hidden;
  }

  .date-option {
    list-style-type: none;
    margin: 5px 0 0 0;
  }

  .date-option li {
    float: left;
    margin: 0 5px 10px 5px;
    width: 110px;
    height: 60px;
    position: relative;
  }

  .date-option label {
    padding-top: 15px;
    font-weight: 600;
  }
}